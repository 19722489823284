header {
    width: 100%;
    position: static;
    z-index: 900;
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
  }
  header .btn, header input[type=submit] {
    font-size: 1rem;
  }

nav {
    height: 80px;
    width: 100%;
    position: relative;
    top: 0;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  nav.logo-centered .menu {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
  }
  nav.logo-centered .nav-cta {
    position: absolute;
    right: 0;
  }
  nav .icon:before {
    background-color: #000000;
  }
  @media (max-width: 991px) {
    nav {
      height: 56px;
    }
  }

  .brand {
    width: auto;
    height: 100%;
    position: relative;
    z-index: 500;
    display: flex;
    align-items: center;
  }
  .brand img {
    height: 32px;
    width: auto;
    opacity: 1;
  }
  @media (max-width: 991px) {
    .brand img {
      height: 32px;
    }
  }

main {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 160px);
}

footer {
    padding: 20px 0;
    font-size: 14px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 24px;
}

.content-wrapper {
    padding-top: 120px;
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
}

.hero-content {
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
}

.subtitle {
    color: #7c7c7c;
    font-size: 24px;
    font-family: "Satoshi Medium", sans-serif;
    margin-bottom: 24px;
}

.title {
    color: #FFFFFF;
    font-weight: 800;
    font-size: 72px;
    font-family: "Satoshi Medium", sans-serif;
    line-height: 1.1;
    margin-bottom: 32px;
}

.description {
    color: #FFFFFF;
    font-size: 20px;
    font-family: "Satoshi Medium", sans-serif;
    line-height: 1.6;
    margin-bottom: 48px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.cta {
    margin-top: 48px;
}

.cta h3 {
    color: #4A9EFF;
    font-size: 32px;
    font-weight: 600;
}

@media (max-width: 768px) {
    .content-wrapper {
        padding-top: 80px;
    }

    .title {
        font-size: 48px;
    }

    .subtitle {
        font-size: 20px;
    }

    .description {
        font-size: 16px;
        padding: 0 24px;
    }

    .cta h3 {
        font-size: 24px;
    }
}
