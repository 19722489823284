.header-search {
    flex: 1.2;
    text-align: left;
    justify-content: flex-start;
    margin-left: 4px;
}
  
.header-search input {
    background-color: rgb(54, 54, 54);
    border: 1px solid grey;
    padding: 5px;
    width: 33%;
}

.total-div {
    flex: 1;
    padding-left: 4px;
    padding-right: 4px;
}
  
.pending-div {
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
}
  
.queue-div {
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
}
  
.blank {
    flex: 1;
}
  
.gradient-div {
  display: block;
  width: 100%;
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  background-color: rgba(197, 224, 250, 0.08);
  border: 1px solid #000000;
  border-radius: 100vw;
  font-family: "Product Sans", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #C5E0FA;
  transition: 0.3s ease;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
  
.queue-div .gradient-div {
    margin-top: 20%;
}
  
.menu-item {
    color:black !important;
}
  
.exchange {
    text-transform:capitalize !important;
    margin-left:23% !important;
}

@media screen and (max-width:376px){
    .container {
      background-image: linear-gradient(#282828 0%,#080808 100%);
      overflow-x: hidden;
    }
    .topbar{
      display: none;
    }
    .article{
      font-size: 14px;
    }
    .header{
      flex-direction: column-reverse;
      margin-top:140px;
      margin-bottom:20px;
    }
    .header-search{
      justify-content: flex-start;
      background-color: #121111;
      padding:5px 5px;
      margin-bottom: 10px;
      margin-top: 8px;
      font-size: 12px;
    }
    .header-connect{
      justify-content:flex-end;
      width:219px;
      position:absolute;
      top:70px;
      right:0px;
      background-color: #121111;
    }
    .gradient-div{
      border-radius: 17px;
      background-image: linear-gradient(rgb(65, 64, 62) 0%, rgb(65, 64, 62) 94%, rgb(65, 64, 62) 96%, rgb(65, 64, 62) 100%);
    }    
    .exchange{
      margin-left:0!important;
    }
    .header-search input{
      width: 100%;
      font-size: 12px;
    }
    .pretty-component{
      width: 215px;
      height: 12px;
    }
    .button-component{
      flex-shrink: 0;
    }
    .content-logo{
      position:absolute;
      bottom:0;
      right:-80px;
      flex-shrink: 0;
      width:316px;
    }
    .total-pending{
      flex-direction: column;
    }
    .blank{
      flex-shrink: 0;
      flex:0;
    }
    .queue-div{
      margin-top:10%;
    }
    .content-three-divs{
      max-width: 70%;
    }
    .total-div, .pending-div, .queue-div{
      font-size: 12px;
      background-color: #121111;
      padding:4px;
      vertical-align: center;
    }
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 461px;
  margin: 0 auto;
}

.btn.btn-primary {
  width: 100%;
  height: 90px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  font-size: 24px;
  text-transform: lowercase;
  border: none;
  transition: transform 0.2s, opacity 0.2s;
  cursor: pointer;
  position: relative;
}

.btn.btn-primary span {
  display: flex;
  align-items: center;
}

.btn.btn-primary img {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.btn.btn-primary img[alt="ETH"] {
  height: 36px;
  width: 36px;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
  margin: 0 auto;
}

.btn.btn-primary:hover {
  transform: scale(1.02);
  opacity: 0.9;
}

.content {
  margin: 20px 0;
}

.box1, .box2 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.overwrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px;
  align-items: center;
}

.left-content {
  text-align: left;
  padding-top: 0;
  margin-top: -80px;
}

.right-content {
  padding-top: 0;
}

.title-text {
  color: #7c7c7c;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 400;
}

.heading-text {
  color: #FFFFFF;
  font-size: 64px;
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 28px;
  letter-spacing: -0.5px;
  font-family: 'Satoshi', sans-serif;
}

.description-text {
  color: #FFFFFF;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 48px;
  max-width: 600px;
  opacity: 0.9;
}

.tokens-held {
  margin-top: 60px;
}

.tokens-label {
  color: #FFFFFF;
  font-size: 64px;
  margin-bottom: 12px;
  font-weight: 800;
  letter-spacing: -0.5px;
}

.tokens-value {
  color: #236bd2;
  font-size: 64px;
  font-weight: 800;
  letter-spacing: -0.5px;
}

.total-value {
  margin-top: 60px;
  font-size: 20px;
  color: #FFFFFF;
  opacity: 0.9;
  text-align: center;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .overwrap {
    grid-template-columns: 1fr;
    gap: 40px;
    padding: 20px;
    align-items: start;
  }

  .left-content {
    margin-top: 0;
    padding-top: 20px;
    text-align: center;
  }

  .right-content {
    padding-top: 20px;
  }

  .description-text {
    margin: 0 auto 40px;
    font-size: 18px;
  }

  .heading-text {
    font-size: 48px;
  }

  .tokens-label, .tokens-value {
    font-size: 48px;
  }

  .button-container {
    max-width: 100%;
    gap: 16px;
  }

  .btn.btn-primary {
    height: 80px;
    padding: 0 24px;
  }

  .btn.btn-primary img {
    right: 24px;
    height: 32px;
    width: 32px;
  }

  .btn.btn-primary img[alt="ETH"] {
    height: 30px;
    width: 30px;
  }

  .total-value {
    margin-top: 40px;
    font-size: 18px;
  }
}

/* Small Mobile Styles */
@media screen and (max-width: 480px) {
  .heading-text {
    font-size: 36px;
  }

  .description-text {
    font-size: 14px;
  }

  .tokens-label, .tokens-value {
    font-size: 36px;
  }

  .btn.btn-primary {
    height: 60px;
    font-size: 18px;
  }

  .btn.btn-primary img {
    height: 32px;
    width: 32px;
  }
}
