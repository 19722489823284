@font-face {
  font-family: 'Satoshi Medium';
  src: url('http://localhost:3000/font/Satoshi Medium.otf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi Medium';
  src: url('http://localhost:3000/font/Satoshi Medium.otf') format('truetype');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
    font-family: 'Satoshi Medium';
    src: url('http://localhost:3000/font/Satoshi Medium.otf') format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
  font-family: 'Satoshi Medium';
  src: url('http://localhost:3000/font/Satoshi Medium.otf') format('truetype');
  font-style: bolder;
  font-weight: 750;
  font-display: swap;
}

@font-face {
    font-family: 'Satoshi Medium';
    src: url('http://localhost:3000/font/Satoshi Medium.otf') format('truetype');
  font-weight: 100 1000;
  font-display: swap;
}

:root {
  scroll-padding-top: 160px;
  scroll-behavior: smooth;
}
@media (max-width: 768px) {
  :root {
    scroll-padding-top: 104px;
  }
}

html,
body {
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  height: 100%;
  min-height: 100vh;
  color: #FFFFFF;
  font-size: 1rem;
  font-family: "Gotham Black", "Arial", sans-serif;
  font-weight: 400;
  background: #0E1A28;
  overflow: hidden;
  position: relative;
  -webkit-text-size-adjust: none;
}
body .sticky {
  top: 104px;
}

* {
  outline: none;
  box-sizing: border-box;
}

section {
  position: relative;
}

img {
  display: block;
}

.container {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}
@media (max-width: 1200px) {
  .container {
    width: 100%;
    padding: 0 16px;
  }
}
.container.container-xxl {
  width: 1400px;
}
@media (max-width: 1400px) {
  .container.container-xxl {
    width: 100%;
    padding: 0 16px;
  }
}
.container.container-lg {
  width: 991px;
}
@media (max-width: 991px) {
  .container.container-lg {
    width: 100%;
    padding: 0 16px;
  }
}
.container.container-md {
  width: 768px;
}
@media (max-width: 768px) {
  .container.container-md {
    width: 100%;
    padding: 0 16px;
  }
}
.container.container-sm {
  width: 576px;
}
@media (max-width: 576px) {
  .container.container-sm {
    width: 100%;
    padding: 0 16px;
  }
}


.btn, input[type=submit] {
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 84px;
  width: 461px;
  line-height: 48px !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  font-family: "Unbounded", "Arial", cursive !important;
  border-radius: 10px !important;
  border: none !important;
  transition: 0.3s ease !important;
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  cursor: pointer !important;
  white-space: nowrap !important;
  position: relative !important;
}

.btn button {
  display: block !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: left !important;
  height: 64px !important;
  width: 100%;
  line-height: 48px !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  font-family: "Satoshi Medium", "Arial", cursive !important;
  border-radius: 10px !important;
  border: none !important;
  transition: 0.3s ease !important;
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  cursor: pointer !important;
  white-space: nowrap !important;
  position: relative !important;
  background-color: #000000;
  color: #FFFFFF !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  padding-left: 20px;
}

.span {
    font-family: "Gothic Black" !important;
}


.btn button image {
    position: absolute;
    right: 0;
    top: 50%; /* adjust as needed */
    transform: translateY(-50%); /* adjust as needed */
}

.btn .icon:before, input[type=submit] .icon:before {
  background-color: #C5E0FA;
}

.btn-small {
  height: 32px;
  line-height: 32px;
}

.btn-large {
  height: 48px;
  line-height: 48px;
}

.btn-round {
  border-radius: 15px;
  padding: 0;
  width: 48px;
}

.btn-square {
  padding: 0;
  width: 48px;
}

.btn-primary, input[type=submit] {
  height:64px;
  max-width: 461px;
  
  background-color: #000000;
  color: #FFFFFF;
  will-change: background-color;
}
.btn-primary:hover, input[type=submit]:hover, .btn-primary:focus, input[type=submit]:focus {
  background-color: hsl(0, 0%, 0%);
}
.btn-primary:focus, input[type=submit]:focus {
  box-shadow: 0 0 0 4px rgba(17, 124, 180, 0.4);
}
.btn-primary:disabled, input[type=submit]:disabled {
  background-color: #DBDBDB;
  color: #A8A8A8;
}
.btn-primary.img {
    float: right;
  width: 30%;
}


.btn-secondary {
  background-color: #001F3F;
  color: #FFFFFF;
  will-change: background-color;
}
.btn-secondary:hover, .btn-secondary:focus {
  background-color: hsl(210.4761904762, 100%, 7.3529411765%);
}
.btn-secondary:focus {
  box-shadow: 0 0 0 4px rgba(0, 31, 63, 0.4);
}
.btn-secondary:disabled {
  background-color: #DBDBDB;
  color: #A8A8A8;
}

.btn-tertiary {
  background-color: #F5F5F5;
  color: #424242;
  will-change: background-color, color;
}
.btn-tertiary:hover, .btn-tertiary:focus {
  background-color: hsl(0, 0%, 91.0784313725%);
}
.btn-tertiary:focus {
  box-shadow: 0 0 0 4px rgba(245, 245, 245, 0.4);
}
.btn-tertiary:disabled {
  color: #DBDBDB;
}

.btn-link {
  background-color: transparent;
  color: #000000;
  will-change: background-color, color;
  padding: 0;
  white-space: normal;
  line-height: 1.3em;
}
.btn-link .icon:before {
  background-color: #000000;
}
.btn-link:hover, .btn-link:focus {
  filter: brightness(1.5);
}
.btn-link:disabled {
  color: #DBDBDB;
}

.tw-connected-wallet__balance {
  display: none !important;
}

/* Aligns */

.text-center {
  text-align: center;

  img {
      margin: 0 auto;
  }
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.d-flex {
  display: flex !important;
  flex-wrap: wrap;
}

.no-wrap {
  flex-wrap: nowrap;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-start {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.absolute-centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.absolute-centered-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-centered-y {
  top: 0%;
  transform: translateY(-50%);
}

@media (min-width: 577px) {
  .d-flex-sm {
      display: flex;
  }
  .d-flex-column-sm {
      display: flex;
      flex-direction: column;
  }
  .align-center-sm {
      align-items: center;
  }
  .align-end-sm {
      align-items: end;
  }
  .align-start-sm {
      align-items: start;
  }
  .justify-center-sm {
      justify-content: center;
  }
  .justify-start-sm {
      justify-content: start;
  }
  .justify-end-sm {
      justify-content: end;
  }
}

@media (min-width: 769px) {
  .d-flex-md {
      display: flex;
  }
  .d-flex-column-md {
      display: flex;
      flex-direction: column;
  }
  .align-center-md {
      align-items: center;
  }
  .align-end-md {
      align-items: end;
  }
  .align-start-md {
      align-items: start;
  }
  .justify-center-md {
      justify-content: center;
  }
  .justify-start-md {
      justify-content: start;
  }
  .justify-end-md {
      justify-content: end;
  }
}

@media (min-width: 992px) {
  .d-flex-lg {
      display: flex;
  }
  .d-flex-column-lg {
      display: flex;
      flex-direction: column;
  }
  .align-center-lg {
      align-items: center;
  }
  .align-end-lg {
      align-items: end;
  }
  .align-start-lg {
      align-items: start;
  }
  .justify-center-lg {
      justify-content: center;
  }
  .justify-start-lg {
      justify-content: start;
  }
  .justify-end-lg {
      justify-content: end;
  }
}

@media (min-width: 1201px) {
  .d-flex-xl {
      display: flex;
  }
  .d-flex-column-xl {
      display: flex;
      flex-direction: column;
  }
  .align-center-xl {
      align-items: center;
  }
  .align-end-xl {
      align-items: end;
  }
  .align-start-xl {
      align-items: start;
  }
  .justify-center-xl {
      justify-content: center;
  }
  .justify-start-xl {
      justify-content: start;
  }
  .justify-end-xl {
      justify-content: end;
  }
}

/* Gap */

.gap-1 {
  gap: 8px;
}

.gap-1-5 {
  gap: 8px * 1.5;
}

.gap-2 {
  gap: 16px;
}

.gap-3 {
  gap: 24px;
}

.gap-4 {
  gap: 32px;
}

.gap-5 {
  gap: 40px;
}

.gap-6 {
  gap: 48px;
}

.gap-7 {
  gap: 48px;
}

.gap-8 {
  gap: 64px;
}

.gap-9 {
  gap: 72px;
}

.gap-10 {
  gap: 80px;
}

@media (min-width: 577px) {
  .gap-sm-1 {
      gap: 8px;
  }
  
  .gap-sm-2 {
      gap: 16px;
  }
  
  .gap-sm-3 {
      gap: 24px;
  }
  
  .gap-sm-4 {
      gap: 32px;
  }
  
  .gap-sm-5 {
      gap: 40px;
  }
  
  .gap-sm-6 {
      gap: 48px;
  }
  
  .gap-sm-7 {
      gap: 48px;
  }
  
  .gap-sm-8 {
      gap: 64px;
  }
  
  .gap-sm-9 {
      gap: 72px;
  }
  
  .gap-sm-10 {
      gap: 80px;
  }
}

@media (min-width: 769px) {
  .gap-md-1 {
      gap: 8px;
  }
  
  .gap-md-2 {
      gap: 16px;
  }
  
  .gap-md-3 {
      gap: 24px;
  }
  
  .gap-md-4 {
      gap: 32px;
  }
  
  .gap-md-5 {
      gap: 40px;
  }
  
  .gap-md-6 {
      gap: 48px;
  }
  
  .gap-md-7 {
      gap: 48px;
  }
  
  .gap-md-8 {
      gap: 64px;
  }
  
  .gap-md-9 {
      gap: 72px;
  }
  
  .gap-md-10 {
      gap: 80px;
  }
}

@media (min-width: 992px) {
  .gap-lg-1 {
      gap: 8px;
  }
  
  .gap-lg-2 {
      gap: 16px;
  }
  
  .gap-lg-3 {
      gap: 24px;
  }
  
  .gap-lg-4 {
      gap: 32px;
  }
  
  .gap-lg-5 {
      gap: 40px;
  }
  
  .gap-lg-6 {
      gap: 48px;
  }
  
  .gap-lg-7 {
      gap: 48px;
  }
  
  .gap-lg-8 {
      gap: 64px;
  }
  
  .gap-lg-9 {
      gap: 72px;
  }
  
  .gap-lg-10 {
      gap: 80px;
  }
}

/* Margins */

.no-m {
    margin: 0 !important;
}

.mc {
    margin: 0 auto;
}

.m-1 {
    margin: 8px !important;
}

.m-2 {
    margin: 16px !important;
}

.m-3 {
    margin: 24px !important;
}

.m-4 {
    margin: 32px !important;
}

.m-5 {
    margin: 40px !important;
}

.m-6 {
    margin: 48px !important;
}

.m-7 {
    margin: 48px !important;
}

.m-8 {
    margin: 64px !important;
}

.m-9 {
    margin: 72px !important;
}

.m-10 {
    margin: 80px !important;
}

.no-mt {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 8px !important;
}

.mt-2 {
    margin-top: 16px !important;
}

.mt-3 {
    margin-top: 24px !important;
}

.mt-4 {
    margin-top: 32px !important;
}

.mt-5 {
    margin-top: 40px !important;
}

.mt-6 {
    margin-top: 48px !important;
}

.mt-7 {
    margin-top: 48px !important;
}

.mt-8 {
    margin-top: 64px !important;
}

.mt-9 {
    margin-top: 72px !important;
}

.mt-10 {
    margin-top: 80px !important;
}

.mt-auto {
    margin-top: auto;
}

.no-mb {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 8px !important;
}

.mb-2 {
    margin-bottom: 16px !important;
}

.mb-3 {
    margin-bottom: 24px !important;
}

.mb-4 {
    margin-bottom: 32px !important;
}

.mb-5 {
    margin-bottom: 40px !important;
}

.mb-6 {
    margin-bottom: 48px !important;
}

.mb-7 {
    margin-bottom: 48px !important;
}

.mb-8 {
    margin-bottom: 64px !important;
}

.mb-9 {
    margin-bottom: 72px !important;
}

.mb-10 {
    margin-bottom: 80px !important;
}

.mb-auto {
    margin-bottom: auto;
}

.no-ml {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: 8px !important;
}

.ml-2 {
    margin-left: 16px !important;
}

.ml-3 {
    margin-left: 24px !important;
}

.ml-4 {
    margin-left: 32px !important;
}

.ml-5 {
    margin-left: 40px !important;
}

.ml-6 {
    margin-left: 48px !important;
}

.ml-7 {
    margin-left: 48px !important;
}

.ml-8 {
    margin-left: 64px !important;
}

.ml-9 {
    margin-left: 72px !important;
}

.ml-10 {
    margin-left: 80px !important;
}

.ml-auto {
    margin-left: auto;
}

.no-mr {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: 8px !important;
}

.mr-2 {
    margin-right: 16px !important;
}

.mr-3 {
    margin-right: 24px !important;
}

.mr-4 {
    margin-right: 32px !important;
}

.mr-5 {
    margin-right: 40px !important;
}

.mr-6 {
    margin-right: 48px !important;
}

.mr-7 {
    margin-right: 48px !important;
}

.mr-8 {
    margin-right: 64px !important;
}

.mr-9 {
    margin-right: 72px !important;
}

.mr-10 {
    margin-right: 80px !important;
}

.mr-auto {
    margin-right: auto;
}

.no-my {
    margin: 0 !important;
}

.my-1 {
    margin: 8px 0 !important;
}

.my-2 {
    margin: (8px*2) 0 !important;
}

.my-3 {
    margin: (8px*3) 0 !important;
}

.my-4 {
    margin: (8px*4) 0 !important;
}

.my-5 {
    margin: (8px*5) 0 !important;
}

.my-6 {
    margin: (8px*6) 0 !important;
}

.my-7 {
    margin: (8px*7) 0 !important;
}

.my-8 {
    margin: (8px*8) 0 !important;
}

.my-9 {
    margin: (8px*9) 0 !important;
}

.my-10 {
    margin: (8px*10) 0 !important;
}

.my-auto {
    margin: 0 auto;
}

.no-mx {
    margin: 0 !important;
}

.mx-1 {
    margin: 8px !important;
}

.mx-2 {
    margin: 0 (8px*2) !important;
}

.mx-3 {
    margin: 0 (8px*3) !important;
}

.mx-4 {
    margin: 0 (8px*4) !important;
}

.mx-5 {
    margin: 0 (8px*5) !important;
}

.mx-6 {
    margin: 0 (8px*6) !important;
}

.mx-7 {
    margin: 0 (8px*7) !important;
}

.mx-8 {
    margin: 0 (8px*8) !important;
}

.mx-9 {
    margin: 0 (8px*9) !important;
}

.mx-10 {
    margin: 0 (8px*10) !important;
}

.mx-auto {
    margin: 0 auto;
}


@media (min-width: 577px) {
    .no-m-sm {
        margin: 0 !important;
    }

    .m-sm-1 {
        margin: 8px !important;
    }

    .m-sm-2 {
        margin: 16px !important;
    }

    .m-sm-3 {
        margin: 24px !important;
    }

    .m-sm-4 {
        margin: 32px !important;
    }

    .m-sm-5 {
        margin: 40px !important;
    }

    .m-sm-6 {
        margin: 48px !important;
    }

    .m-sm-7 {
        margin: 48px !important;
    }

    .m-sm-8 {
        margin: 64px !important;
    }

    .m-sm-9 {
        margin: 72px !important;
    }

    .m-sm-10 {
        margin: 80px !important;
    }

    .no-mt-sm {
        margin-top: 0 !important;
    }

    .mt-sm-1 {
        margin-top: 8px !important;
    }

    .mt-sm-2 {
        margin-top: 16px !important;
    }

    .mt-sm-3 {
        margin-top: 24px !important;
    }

    .mt-sm-4 {
        margin-top: 32px !important;
    }

    .mt-sm-5 {
        margin-top: 40px !important;
    }

    .mt-sm-6 {
        margin-top: 48px !important;
    }

    .mt-sm-7 {
        margin-top: 48px !important;
    }

    .mt-sm-8 {
        margin-top: 64px !important;
    }

    .mt-sm-9 {
        margin-top: 72px !important;
    }

    .mt-sm-10 {
        margin-top: 80px !important;
    }

    .no-mb-sm {
        margin-bottom: 0 !important;
    }

    .mb-sm-1 {
        margin-bottom: 8px !important;
    }

    .mb-sm-2 {
        margin-bottom: 16px !important;
    }

    .mb-sm-3 {
        margin-bottom: 24px !important;
    }

    .mb-sm-4 {
        margin-bottom: 32px !important;
    }

    .mb-sm-5 {
        margin-bottom: 40px !important;
    }

    .mb-sm-6 {
        margin-bottom: 48px !important;
    }

    .mb-sm-7 {
        margin-bottom: 48px !important;
    }

    .mb-sm-8 {
        margin-bottom: 64px !important;
    }

    .mb-sm-9 {
        margin-bottom: 72px !important;
    }

    .mb-sm-10 {
        margin-bottom: 80px !important;
    }

    .no-ml-sm {
        margin-left: 0 !important;
    }

    .ml-sm-1 {
        margin-left: 8px !important;
    }

    .ml-sm-2 {
        margin-left: 16px !important;
    }

    .ml-sm-3 {
        margin-left: 24px !important;
    }

    .ml-sm-4 {
        margin-left: 32px !important;
    }

    .ml-sm-5 {
        margin-left: 40px !important;
    }

    .ml-sm-6 {
        margin-left: 48px !important;
    }

    .ml-sm-7 {
        margin-left: 48px !important;
    }

    .ml-sm-8 {
        margin-left: 64px !important;
    }

    .ml-sm-9 {
        margin-left: 72px !important;
    }

    .ml-sm-10 {
        margin-left: 80px !important;
    }

    .no-mr-sm {
        margin-right: 0 !important;
    }

    .mr-sm-1 {
        margin-right: 8px !important;
    }

    .mr-sm-2 {
        margin-right: 16px !important;
    }

    .mr-sm-3 {
        margin-right: 24px !important;
    }

    .mr-sm-4 {
        margin-right: 32px !important;
    }

    .mr-sm-5 {
        margin-right: 40px !important;
    }

    .mr-sm-6 {
        margin-right: 48px !important;
    }

    .mr-sm-7 {
        margin-right: 48px !important;
    }

    .mr-sm-8 {
        margin-right: 64px !important;
    }

    .mr-sm-9 {
        margin-right: 72px !important;
    }

    .mr-sm-10 {
        margin-right: 80px !important;
    }

    .my-sm-1 {
        margin: 8px 0 !important;
    }

    .my-sm-2 {
        margin: 16px 0 !important;
    }

    .my-sm-3 {
        margin: 24px 0 !important;
    }

    .my-sm-4 {
        margin: 32px 0 !important;
    }

    .my-sm-5 {
        margin: 40px 0 !important;
    }

    .my-sm-6 {
        margin: 48px 0 !important;
    }

    .my-sm-7 {
        margin: 48px 0 !important;
    }

    .my-sm-8 {
        margin: 64px 0 !important;
    }

    .my-sm-9 {
        margin: 72px 0 !important;
    }

    .my-sm-10 {
        margin: 80px 0 !important;
    }

    .mx-sm-1 {
        margin: 0 8px !important;
    }

    .mx-sm-2 {
        margin: 0 16px !important;
    }

    .mx-sm-3 {
        margin: 0 24px !important;
    }

    .mx-sm-4 {
        margin: 0 32px !important;
    }

    .mx-sm-5 {
        margin: 0 40px !important;
    }

    .mx-sm-6 {
        margin: 0 48px !important;
    }

    .mx-sm-7 {
        margin: 0 48px !important;
    }

    .mx-sm-8 {
        margin: 0 64px !important;
    }

    .mx-sm-9 {
        margin: 0 72px !important;
    }

    .mx-sm-10 {
        margin: 0 80px !important;
    }
}

@media (min-width: 769px) {
    .no-m-md {
        margin: 0 !important;
    }

    .m-md-1 {
        margin: 8px !important;
    }

    .m-md-2 {
        margin: 16px !important;
    }

    .m-md-3 {
        margin: 24px !important;
    }

    .m-md-4 {
        margin: 32px !important;
    }

    .m-md-5 {
        margin: 40px !important;
    }

    .m-md-6 {
        margin: 48px !important;
    }

    .m-md-7 {
        margin: 48px !important;
    }

    .m-md-8 {
        margin: 64px !important;
    }

    .m-md-9 {
        margin: 72px !important;
    }

    .m-md-10 {
        margin: 80px !important;
    }

    .no-mt-md {
        margin-top: 0 !important;
    }

    .mt-md-1 {
        margin-top: 8px !important;
    }

    .mt-md-2 {
        margin-top: 16px !important;
    }

    .mt-md-3 {
        margin-top: 24px !important;
    }

    .mt-md-4 {
        margin-top: 32px !important;
    }

    .mt-md-5 {
        margin-top: 40px !important;
    }

    .mt-md-6 {
        margin-top: 48px !important;
    }

    .mt-md-7 {
        margin-top: 48px !important;
    }

    .mt-md-8 {
        margin-top: 64px !important;
    }

    .mt-md-9 {
        margin-top: 72px !important;
    }

    .mt-md-10 {
        margin-top: 80px !important;
    }

    .no-mb-md {
        margin-bottom: 0 !important;
    }

    .mb-md-1 {
        margin-bottom: 8px !important;
    }

    .mb-md-2 {
        margin-bottom: 16px !important;
    }

    .mb-md-3 {
        margin-bottom: 24px !important;
    }

    .mb-md-4 {
        margin-bottom: 32px !important;
    }

    .mb-md-5 {
        margin-bottom: 40px !important;
    }

    .mb-md-6 {
        margin-bottom: 48px !important;
    }

    .mb-md-7 {
        margin-bottom: 48px !important;
    }

    .mb-md-8 {
        margin-bottom: 64px !important;
    }

    .mb-md-9 {
        margin-bottom: 72px !important;
    }

    .mb-md-10 {
        margin-bottom: 80px !important;
    }

    .no-m-l-md {
        margin-left: 0 !important;
    }

    .ml-md-1 {
        margin-left: 8px !important;
    }

    .ml-md-2 {
        margin-left: 16px !important;
    }

    .ml-md-3 {
        margin-left: 24px !important;
    }

    .ml-md-4 {
        margin-left: 32px !important;
    }

    .ml-md-5 {
        margin-left: 40px !important;
    }

    .ml-md-6 {
        margin-left: 48px !important;
    }

    .ml-md-7 {
        margin-left: 48px !important;
    }

    .ml-md-8 {
        margin-left: 64px !important;
    }

    .ml-md-9 {
        margin-left: 72px !important;
    }

    .ml-md-10 {
        margin-left: 80px !important;
    }

    .no-mr-md {
        margin-right: 0 !important;
    }

    .mr-md-1 {
        margin-right: 8px !important;
    }

    .mr-md-2 {
        margin-right: 16px !important;
    }

    .mr-md-3 {
        margin-right: 24px !important;
    }

    .mr-md-4 {
        margin-right: 32px !important;
    }

    .mr-md-5 {
        margin-right: 40px !important;
    }

    .mr-md-6 {
        margin-right: 48px !important;
    }

    .mr-md-7 {
        margin-right: 48px !important;
    }

    .mr-md-8 {
        margin-right: 64px !important;
    }

    .mr-md-9 {
        margin-right: 72px !important;
    }

    .mr-md-10 {
        margin-right: 80px !important;
    }

    .my-md-1 {
        margin: 8px 0 !important;
    }

    .my-md-2 {
        margin: 16px 0 !important;
    }

    .my-md-3 {
        margin: 24px 0 !important;
    }

    .my-md-4 {
        margin: 32px 0 !important;
    }

    .my-md-5 {
        margin: 40px 0 !important;
    }

    .my-md-6 {
        margin: 48px 0 !important;
    }

    .my-md-7 {
        margin: 48px 0 !important;
    }

    .my-md-8 {
        margin: 64px 0 !important;
    }

    .my-md-9 {
        margin: 72px 0 !important;
    }

    .my-md-10 {
        margin: 80px 0 !important;
    }

    .mx-md-1 {
        margin: 0 8px !important;
    }

    .mx-md-2 {
        margin: 0 16px !important;
    }

    .mx-md-3 {
        margin: 0 24px !important;
    }

    .mx-md-4 {
        margin: 0 32px !important;
    }

    .mx-md-5 {
        margin: 0 40px !important;
    }

    .mx-md-6 {
        margin: 0 48px !important;
    }

    .mx-md-7 {
        margin: 0 48px !important;
    }

    .mx-md-8 {
        margin: 0 64px !important;
    }

    .mx-md-9 {
        margin: 0 72px !important;
    }

    .mx-md-10 {
        margin: 0 80px !important;
    }
}

@media (min-width: 992px) {
    .no-m-lg {
        margin: 0 !important;
    }

    .m-lg-1 {
        margin: 8px !important;
    }

    .m-lg-2 {
        margin: 16px !important;
    }

    .m-lg-3 {
        margin: 24px !important;
    }

    .m-lg-4 {
        margin: 32px !important;
    }

    .m-lg-5 {
        margin: 40px !important;
    }

    .m-lg-6 {
        margin: 48px !important;
    }

    .m-lg-7 {
        margin: 48px !important;
    }

    .m-lg-8 {
        margin: 64px !important;
    }

    .m-lg-9 {
        margin: 72px !important;
    }

    .m-lg-10 {
        margin: 80px !important;
    }

    .no-mt-lg {
        margin-top: 0 !important;
    }

    .mt-lg-1 {
        margin-top: 8px !important;
    }

    .mt-lg-2 {
        margin-top: 16px !important;
    }

    .mt-lg-3 {
        margin-top: 24px !important;
    }

    .mt-lg-4 {
        margin-top: 32px !important;
    }

    .mt-lg-5 {
        margin-top: 40px !important;
    }

    .mt-lg-6 {
        margin-top: 48px !important;
    }

    .mt-lg-7 {
        margin-top: 48px !important;
    }

    .mt-lg-8 {
        margin-top: 64px !important;
    }

    .mt-lg-9 {
        margin-top: 72px !important;
    }

    .mt-lg-10 {
        margin-top: 80px !important;
    }

    .no-mb-lg {
        margin-bottom: 0 !important;
    }

    .mb-lg-1 {
        margin-bottom: 8px !important;
    }

    .mb-lg-2 {
        margin-bottom: 16px !important;
    }

    .mb-lg-3 {
        margin-bottom: 24px !important;
    }

    .mb-lg-4 {
        margin-bottom: 32px !important;
    }

    .mb-lg-5 {
        margin-bottom: 40px !important;
    }

    .mb-lg-6 {
        margin-bottom: 48px !important;
    }

    .mb-lg-7 {
        margin-bottom: 48px !important;
    }

    .mb-lg-8 {
        margin-bottom: 64px !important;
    }

    .mb-lg-9 {
        margin-bottom: 72px !important;
    }

    .mb-lg-10 {
        margin-bottom: 80px !important;
    }

    .no-ml-lg {
        margin-left: 0 !important;
    }

    .ml-lg-1 {
        margin-left: 8px !important;
    }

    .ml-lg-2 {
        margin-left: 16px !important;
    }

    .ml-lg-3 {
        margin-left: 24px !important;
    }

    .ml-lg-4 {
        margin-left: 32px !important;
    }

    .ml-lg-5 {
        margin-left: 40px !important;
    }

    .ml-lg-6 {
        margin-left: 48px !important;
    }

    .ml-lg-7 {
        margin-left: 48px !important;
    }

    .ml-lg-8 {
        margin-left: 64px !important;
    }

    .ml-lg-9 {
        margin-left: 72px !important;
    }

    .ml-lg-10 {
        margin-left: 80px !important;
    }

    .ml-lg-auto {
        margin-left: auto;
    }

    .no-mr-lg {
        margin-right: 0 !important;
    }

    .mr-lg-1 {
        margin-right: 8px !important;
    }

    .mr-lg-2 {
        margin-right: 16px !important;
    }

    .mr-lg-3 {
        margin-right: 24px !important;
    }

    .mr-lg-4 {
        margin-right: 32px !important;
    }

    .mr-lg-5 {
        margin-right: 40px !important;
    }

    .mr-lg-6 {
        margin-right: 48px !important;
    }

    .mr-lg-7 {
        margin-right: 48px !important;
    }

    .mr-lg-8 {
        margin-right: 64px !important;
    }

    .mr-lg-9 {
        margin-right: 72px !important;
    }

    .mr-lg-10 {
        margin-right: 80px !important;
    }

     .my-lg-1 {
        margin: 8px 0 !important;
    }

    .my-lg-2 {
        margin: 16px 0 !important;
    }

    .my-lg-3 {
        margin: 24px 0 !important;
    }

    .my-lg-4 {
        margin: 32px 0 !important;
    }

    .my-lg-5 {
        margin: 40px 0 !important;
    }

    .my-lg-6 {
        margin: 48px 0 !important;
    }

    .my-lg-7 {
        margin: 48px 0 !important;
    }

    .my-lg-8 {
        margin: 64px 0 !important;
    }

    .my-lg-9 {
        margin: 72px 0 !important;
    }

    .my-lg-10 {
        margin: 80px 0 !important;
    }

    .mx-lg-1 {
        margin: 0 8px !important;
    }

    .mx-lg-2 {
        margin: 0 16px !important;
    }

    .mx-lg-3 {
        margin: 0 24px !important;
    }

    .mx-lg-4 {
        margin: 0 32px !important;
    }

    .mx-lg-5 {
        margin: 0 40px !important;
    }

    .mx-lg-6 {
        margin: 0 48px !important;
    }

    .mx-lg-7 {
        margin: 0 48px !important;
    }

    .mx-lg-8 {
        margin: 0 64px !important;
    }

    .mx-lg-9 {
        margin: 0 72px !important;
    }

    .mx-lg-10 {
        margin: 0 80px !important;
    }
}

@media (min-width: 1201px) {
    .no-m-xl {
        margin: 0 !important;
    }

    .m-xl-1 {
        margin: 8px !important;
    }

    .m-xl-2 {
        margin: 16px !important;
    }

    .m-xl-3 {
        margin: 24px !important;
    }

    .m-xl-4 {
        margin: 32px !important;
    }

    .m-xl-5 {
        margin: 40px !important;
    }

    .m-xl-6 {
        margin: 48px !important;
    }

    .m-xl-7 {
        margin: 48px !important;
    }

    .m-xl-8 {
        margin: 64px !important;
    }

    .m-xl-9 {
        margin: 72px !important;
    }

    .m-xl-10 {
        margin: 80px !important;
    }

    .no-mt-xl {
        margin-top: 0 !important;
    }

    .mt-xl-1 {
        margin-top: 8px !important;
    }

    .mt-xl-2 {
        margin-top: 16px !important;
    }

    .mt-xl-3 {
        margin-top: 24px !important;
    }

    .mt-xl-4 {
        margin-top: 32px !important;
    }

    .mt-xl-5 {
        margin-top: 40px !important;
    }

    .mt-xl-6 {
        margin-top: 48px !important;
    }

    .mt-xl-7 {
        margin-top: 48px !important;
    }

    .mt-xl-8 {
        margin-top: 64px !important;
    }

    .mt-xl-9 {
        margin-top: 72px !important;
    }

    .mt-xl-10 {
        margin-top: 80px !important;
    }

    .no-mb-xl {
        margin-bottom: 0 !important;
    }

    .mb-xl-1 {
        margin-bottom: 8px !important;
    }

    .mb-xl-2 {
        margin-bottom: 16px !important;
    }

    .mb-xl-3 {
        margin-bottom: 24px !important;
    }

    .mb-xl-4 {
        margin-bottom: 32px !important;
    }

    .mb-xl-5 {
        margin-bottom: 40px !important;
    }

    .mb-xl-6 {
        margin-bottom: 48px !important;
    }

    .mb-xl-7 {
        margin-bottom: 48px !important;
    }

    .mb-xl-8 {
        margin-bottom: 64px !important;
    }

    .mb-xl-9 {
        margin-bottom: 72px !important;
    }

    .mb-xl-10 {
        margin-bottom: 80px !important;
    }

    .no-ml-xl {
        margin-left: 0 !important;
    }

    .ml-xl-1 {
        margin-left: 8px !important;
    }

    .ml-xl-2 {
        margin-left: 16px !important;
    }

    .ml-xl-3 {
        margin-left: 24px !important;
    }

    .ml-xl-4 {
        margin-left: 32px !important;
    }

    .ml-xl-5 {
        margin-left: 40px !important;
    }

    .ml-xl-6 {
        margin-left: 48px !important;
    }

    .ml-xl-7 {
        margin-left: 48px !important;
    }

    .ml-xl-8 {
        margin-left: 64px !important;
    }

    .ml-xl-9 {
        margin-left: 72px !important;
    }

    .ml-xl-10 {
        margin-left: 80px !important;
    }

    .no-mr-xl {
        margin-right: 0 !important;
    }

    .mr-xl-1 {
        margin-right: 8px !important;
    }

    .mr-xl-2 {
        margin-right: 16px !important;
    }

    .mr-xl-3 {
        margin-right: 24px !important;
    }

    .mr-xl-4 {
        margin-right: 32px !important;
    }

    .mr-xl-5 {
        margin-right: 40px !important;
    }

    .mr-xl-6 {
        margin-right: 48px !important;
    }

    .mr-xl-7 {
        margin-right: 48px !important;
    }

    .mr-xl-8 {
        margin-right: 64px !important;
    }

    .mr-xl-9 {
        margin-right: 72px !important;
    }

    .mr-xl-10 {
        margin-right: 80px !important;
    }
}

.my-xl-1 {
    margin: 8px 0 !important;
}

.my-xl-2 {
    margin: 16px 0 !important;
}

.my-xl-3 {
    margin: 24px 0 !important;
}

.my-xl-4 {
    margin: 32px 0 !important;
}

.my-xl-5 {
    margin: 40px 0 !important;
}

.my-xl-6 {
    margin: 48px 0 !important;
}

.my-xl-7 {
    margin: 48px 0 !important;
}

.my-xl-8 {
    margin: 64px 0 !important;
}

.my-xl-9 {
    margin: 72px 0 !important;
}

.my-xl-10 {
    margin: 80px 0 !important;
}

.mx-xl-1 {
    margin: 0 8px !important;
}

.mx-xl-2 {
    margin: 0 16px !important;
}

.mx-xl-3 {
    margin: 0 24px !important;
}

.mx-xl-4 {
    margin: 0 32px !important;
}

.mx-xl-5 {
    margin: 0 40px !important;
}

.mx-xl-6 {
    margin: 0 48px !important;
}

.mx-xl-7 {
    margin: 0 48px !important;
}

.mx-xl-8 {
    margin: 0 64px !important;
}

.mx-xl-9 {
    margin: 0 72px !important;
}

.mx-xl-10 {
    margin: 0 80px !important;
}

/* Typography */

h1,
.h1 {
  font-size: clamp(2rem, 5vw, 3.5rem);
  font-weight: 700;
  line-height: 1.15em;
  margin: 0 0 1rem;
  padding: 0;
  color: #C5E0FA;
  font-family: "Unbounded", "Arial", cursive;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
          background-clip: text;
  background-image: linear-gradient(45deg, hsl(200.6134969325, 82.7411167513%, 68.6274509804%) 0%, #C5E0FA 75%);
}

h2,
.h2 {
  font-size: clamp(2rem, 5vw, 2.5rem);
  font-weight: 700;
  margin: 0 0 1rem;
  padding: 0;
  color: #C5E0FA;
  font-family: "Unbounded", "Arial", cursive;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
          background-clip: text;
  background-image: linear-gradient(45deg, hsl(200.6134969325, 82.7411167513%, 68.6274509804%) 0%, #C5E0FA 75%);
}

h3, .accordion-item .accordion-header .btn-expandable,
.h3 {
  font-size: clamp(1.5rem, 5vw, 1.75rem);
  font-weight: 700;
  line-height: 1.3em;
  margin: 0 0 1rem;
  padding: 0;
  color: #C5E0FA;
  font-family: "Unbounded", "Arial", cursive;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
          background-clip: text;
  background-image: linear-gradient(45deg, hsl(200.6134969325, 82.7411167513%, 68.6274509804%) 0%, #C5E0FA 75%);
}

h4, .price,
.h4 {
  font-size: clamp(1.25rem, 5vw, 1.5rem);
  font-weight: 700;
  line-height: 1.3em;
  margin: 0 0 0.5rem;
  padding: 0;
  color: #C5E0FA;
  font-family: "Unbounded", "Arial", cursive;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
          background-clip: text;
  background-image: linear-gradient(45deg, hsl(200.6134969325, 82.7411167513%, 68.6274509804%) 0%, #C5E0FA 75%);
}

h5,
.h5 {
  font-size: clamp(1rem, 5vw, 1rem);
  font-weight: 500;
  line-height: 1.5em;
  margin: 0 0 0.5rem;
  padding: 0;
  color: #C5E0FA;
  font-family: "Product Sans", "Arial", sans-serif;
}

p,
.p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5em;
  margin: 0 0 1rem;
  padding: 0;
  color: #C5E0FA;
}
@media (max-width: 576px) {
  p,
  .p {
    font-size: 1rem;
  }
}

.lead {
  font-size: clamp(1.25rem, 5vw, 1.5rem);
  font-weight: 400;
  line-height: 1.5em;
  margin: 0 0 1rem;
  padding: 0;
}

.small {
  font-size: clamp(0.75rem, 5vw, 0.75rem);
  font-weight: 400;
  line-height: 1.5em;
  margin: 0 0 0.5rem;
  padding: 0;
}

.label {
  font-size: clamp(0.75rem, 5vw, 1rem);
  font-weight: 700;
  line-height: 157%;
  margin: 0 0 1rem;
  padding: 0;
  text-transform: uppercase;
  font-style: italic;
  letter-spacing: 6px;
}

.large {
  font-size: clamp(2rem, 5vw, 2.5rem);
  font-weight: 400;
  line-height: 1em;
  margin: 0 0 1rem;
  padding: 0;
}

a {
  text-decoration: none;
  color: #000000;
}
a.link {
  font-weight: 700;
  will-change: color;
  white-space: nowrap;
}
a.link .icon:before {
  background-color: #000000;
}

ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
ul li {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5em;
  margin: 0 0 1rem;
  padding: 0;
  color: #C5E0FA;
  position: relative;
}
ul.bullets {
  list-style: disc;
  padding-left: 16px;
}
ul.bullets li {
  margin: 0;
}

.light-text h1,
.light-text .h1,
.light-text h2,
.light-text .h2,
.light-text h3,
.light-text .accordion-item .accordion-header .btn-expandable,
.accordion-item .accordion-header .light-text .btn-expandable,
.light-text .h3,
.light-text h4,
.light-text .price,
.light-text .h4,
.light-text h5,
.light-text .h5,
.light-text p {
  color: #FFF;
}

.dark-text {
  color: #C5E0FA;
}

.full-width .btn {
  width: 100%;
}